import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import FileSaver from 'file-saver';

import {
  base64toBlob,
  downloadUrl,
  isEmptyArray,
  setProducts,
  sortArrayByDates,
} from '../../../scripts/Util';
import { formatDate } from '../../../scripts/Constants';
import {
  menuCode,
  productsCode,
  showErrorToast,
  showSuccessToast,
} from '../../shared/constants/Constants';
import { Policy } from '../../shared/model/Policy';
import UserNetPrivada from '../../shared/services/UserNetPrivada';
import { Ticket } from '../../shared/model/Ticket';

import { DetailProductsByUser, ProductsByUser } from '../../components/templates';
import { ProductPolicies } from '../../shared/model/ProductPolicies';

import './index.scss';

export const getTypeTicket = (ticketMenuId) => {
  switch (ticketMenuId) {
    case '20000001':
      return 'RV';
    case '20000008':
      return 'RT';

    case '20000003':
      return 'RT';
    default:
      return 'RV';
  }
};
export const voucherType = (type) => {
  let value = 'Factura';
  switch (type) {
    case '01':
      value = 'Factura';
      break;
    case '03':
      value = 'Boleta de venta';
      break;
    case '07':
      value = 'Nota de crédito';
      break;
    case '08':
      value = 'Nota de débito';
      break;
  }
  return value;
};
const queryPolicy = gql`
  query ObtenerEnviosPorPoliza($policyId: ID!, $productId: ID!, $riskId: ID!) {
    mailings(policyId: $policyId, productId: $productId, riskId: $riskId) {
      items {
        id
        date
        email
        file
      }
    }
  }
`;

export default function ProductsByUserPage(props) {
  const { id } = useParams();
  const beneficary = useSelector((state) => state.beneficary);
  const [productSelected, setProductSelected] = useState(null);
  const [detailSelected, setDetailSelected] = useState(null);
  const [productsByUser, setProductsByUser] = useState([]);
  const [listPoliceByProducts, setListPoliceByProducts] = useState([]);
  const [loadingConstancia, setLoadingConstancia] = useState(false);
  const [loadingPolicy, setLoadingPolicy] = useState(false);
  const [loadingComprobante, setLoadingComprobante] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [isPensioner, setIsPensioner] = useState(false);
  const [soatBase64, setSoatBase64] = useState({ base64: null, loading: false, hasError: false });
  const [soatComprobanteBase64, setSoatComprobanteBase64] = useState({
    loading: false,
    hasError: false,
    listComprobantes: [],
    header: [],
  });
  const [setLocationPolicy, { data: dataPolicy }] = useLazyQuery(queryPolicy);
  const { user } = props.location.state || {};
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);

  const selectUserProduct = (item) => {
    setProductSelected(item);
  };

  const selectDetail = (item) => {
    setDetailSelected(item);
    if (item && item.producto.trim().toUpperCase() === productsCode.SOAT.toUpperCase()) {
      getCertificate(item.nroPolicy);
    }
  };

  const getListProductPolicies = async () => {
    try {
      setLoading(true);
      const response = await UserNetPrivada.responseGetProductPolicies({ 'customer-id': id });
      if (response?.data) {
        const list = response.data.map((p) => new ProductPolicies(p));
        const listProducts = setProducts(list);
        setProductsByUser([...listProducts]);
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const getPoliciesByProduct = async () => {
    setLoadingDetails(true);
    const polices = productSelected.policies.map((p) => new Policy(p));
    setListPoliceByProducts([...polices]);
    setLoadingDetails(false);
  };

  const getAnnuityInfo = async (year, policyId) => {
    try {
      const response = await UserNetPrivada.responseGetTicketByAnnuity(
        year,
        policyId.toString().padStart(10, '0'),
        productSelected.menuId
      );
      if (response) {
        let dataFormatted = response.map((item) => new Ticket(item));
        dataFormatted = validateIsBeneficiary(dataFormatted);
        return dataFormatted;
      }
      return [];
    } catch (error) {
      console.log('error', error);
      return [];
    }
  };

  const validateIsBeneficiary = (list) => {
    const isBeneficiary =
      list.find((l) => l.receiver.document.number === beneficary.nroDoc) || false;
    if (isBeneficiary) {
      return list.filter((l) => l.receiver.document.number.trim() === beneficary.nroDoc);
    }
    return [];
  };

  const downloadPolicy = (dataPolicy) => {
    setLoadingPolicy(true);
    const {
      mailings: { items },
    } = dataPolicy;
    if (!isEmptyArray(items)) {
      const policiesDigital = sortArrayByDates(items, 'date');
      const url = policiesDigital.shift().file || null;
      downloadUrl(url);
    } else {
      showErrorToast('Su póliza digital no se encuentra disponible');
    }
    setLoadingPolicy(false);
  };

  const downloadTicket = async (ticket) => {
    try {
      const type = getTypeTicket(productSelected.menuId);
      setLoadingTicket(true);

      const nameBase =
        type === 'RV'
          ? 'boleta_rrvv'
          : type === 'RT'
          ? 'liquidacionDePago_rt'
          : 'liquidacionDePago_at';

      const documentReceptor = ticket ? ticket.receiver.document.number : '08228344';
      const date = ticket ? ticket.period : '202001';
      const name = `${nameBase}_${documentReceptor}_${date}`;

      await UserNetPrivada.responseGetDocuments(ticket.id, type, name);
      setLoadingTicket(false);
    } catch (error) {
      console.log(error);
    }
  };
  const validatePensioner = async (policy) => {
    try {
      const header = {
        policy: policy.toString().padStart(10, '0'),
        'segment-id': productSelected.menuId,
        'customer-id': id,
      };
      const { data, status } = await UserNetPrivada.responseValidateByPolicy(header);

      if (data && status && status.id === 200) {
        const {
          receiver: { document },
        } = data;
        setIsPensioner(beneficary.nroDoc === document.number);
      } else {
        setIsPensioner(false);
      }
    } catch (error) {
      setIsPensioner(false);
    }
  };

  const generateConstancy = async () => {
    const isRRVV = productSelected.menuId === menuCode.RENTA_VITALICIA;
    let headers = {};
    try {
      setLoadingConstancia(true);
      if (isRRVV) {
        headers = { policy: detailSelected.nroPolicy, 'customer-id': id };
        await UserNetPrivada.resposeGetPensionerConstancy(headers);
      } else {
        headers = { 'segment-id': productSelected.menuId };
        await UserNetPrivada.resposeGetConstancy(
          productSelected,
          detailSelected,
          beneficary,
          false,
          '',
          headers
        );
      }
    } catch (error) {
      showErrorToast(`Error al descargar la Constancia de ${isRRVV ? 'Pensionista' : 'Asegurado'}`);
    } finally {
      setLoadingConstancia(false);
    }
  };

  const generatePolicyGraph = async () => {
    if (dataPolicy) {
      downloadPolicy(dataPolicy);
    } else {
      setLoadingPolicy(true);
      const { menuId } = productSelected;
      const { nroPolicy, idProduct, nbranch } = detailSelected;
      const product =
        menuId === menuCode.AHORRO_TOTAL
          ? '2001'
          : menuId === menuCode.RENTA_TOTAL
          ? '2000'
          : idProduct.toString();
      setLocationPolicy({
        variables: {
          policyId: nroPolicy.toString(),
          productId: product,
          riskId: nbranch.toString(),
        },
      });
    }
  };

  const generateDigitalPolicy = async () => {
    try {
      setLoadingPolicy(true);
      const { nroPolicy, idProduct, nbranch, nroCertificate } = detailSelected;
      const params = {
        branchId: nbranch,
        productId: idProduct,
        policy: nroPolicy.toString(),
        document: beneficary.userName,
        certificateId: nroCertificate.toString(),
      };
      const { data, status } = await UserNetPrivada.responseGetDigitaPolicy(params);

      if (status?.id === 201) {
        downloadUrl(data?.file);
      } else {
        showErrorToast('Su póliza digital no se encuentra disponible');
      }
    } catch (error) {
      showErrorToast('Su póliza digital no se encuentra disponible');
    } finally {
      setLoadingPolicy(false);
    }
  };

  const generatePolicy = async () => {
    if (detailSelected && productSelected) {
      const isDesgravamenAP = [menuCode.DESGRAVAMEN, menuCode.ACCIDENTES_PERSONALES].includes(
        productSelected.menuId
      );
      if (isDesgravamenAP) {
        generateDigitalPolicy();
      } else {
        generatePolicyGraph();
      }
    }
  };

  const sendEmail = async (ticketIds, email, closeModal) => {
    try {
      const response = await UserNetPrivada.responseSendEmailBoletas(
        ticketIds,
        email,
        beneficary,
        productSelected.menuId
      );

      if (response.statusCode === 200) {
        showSuccessToast(`Se envio correctamente al correo ${email}`);
      } else {
        showErrorToast('Error al enviar correo');
      }
      closeModal();
    } catch (error) {
      showErrorToast('Error al enviar correo');
      console.log(error);
    }
  };

  const getCertificate = async (nroPolicy) => {
    try {
      setSoatBase64({ ...soatBase64, loading: true });
      const response = await UserNetPrivada.responseGetCertificate();
      if (response.statusCode === 200) {
        const keyFile = response.data.key;

        if (keyFile !== null) {
          const responseFile = await UserNetPrivada.responseGetSoatConstancia(
            `Bearer ${keyFile}`,
            nroPolicy
          );
          const soatBase64Response = { base64: responseFile.file, loading: false, hasError: false };
          setSoatBase64({ ...soatBase64Response });
        } else {
          throw new Error('key null');
        }
      }
    } catch (error) {
      setSoatBase64({ ...soatBase64, loading: false, hasError: true });
      showErrorToast('Error al cargar certificado  digital');
      console.error(error);
    }
  };

  const getComprobantePoliza = async () => {
    try {
      const data = {
        'branch-id': parseInt(detailSelected.nbranch),
        'product-id': parseInt(detailSelected.idProduct),
        policy: detailSelected.nroPolicy,
        certificate: detailSelected.nroCertificate,
      };
      setSoatComprobanteBase64({
        ...soatComprobanteBase64,
        loading: true,
        hasError: false,
        headers: [],
      });

      const response = await UserNetPrivada.responseValidateComprobantePago(data);

      if (response.status.id === 200 && response.data) {
        const comprobantePagoBase64Response = {
          loading: false,
          hasError: false,
        };
        const headerVoucher = [
          {
            elementProp: 'download',
            headerColumn: '',
            icons: [
              { name: 'filePDF', type: 'pdf' },
              { name: 'fileXML', type: 'xml' },
            ],
          },
          { elementProp: 'voucherTypeFormatted', headerColumn: 'Tipo' },
          { elementProp: 'serialNumber', headerColumn: 'Número de Serie' },
          { elementProp: 'number', headerColumn: 'Número' },
          { elementProp: 'dateFormatted', headerColumn: 'Fecha' },
          { elementProp: 'amountFormatted', headerColumn: 'Monto' },
          { elementProp: 'status', headerColumn: 'Estado' },
        ];
        const filter = [
          {
            type: 'numeric',
            label: 'Número de Comprobante',
            elementProp: 'number',
            maxLength: '10',
          },
          {
            type: 'range',
            label: 'Rango de Fecha de Emisión',
            elementProp: 'date',
            inputs: ['startDate', 'endDate'],
          },
        ];

        const dataResponse = sortArrayByDates(response.data).map((item) => {
          return {
            ...item,
            dateFormatted: new Date(item.date).toLocaleString('en-GB', formatDate).split(',')[0],
            amountFormatted: `${item.currency || ''} ${item.amount.toFixed(2)}`,
            voucherTypeFormatted: voucherType(item.voucherType),
          };
        });

        setSoatComprobanteBase64({
          ...comprobantePagoBase64Response,
          loading: false,
          listComprobantes: dataResponse,
          header: headerVoucher,
          filter,
        });
      } else {
        setSoatComprobanteBase64({
          ...soatComprobanteBase64,
          loading: false,
          hasError: true,
          listComprobantes: [],
        });
      }
    } catch (error) {
      setSoatComprobanteBase64({
        ...soatComprobanteBase64,
        loading: false,
        hasError: true,
        listComprobantes: [],
      });
      showErrorToast('Error al cargar comprobante de pago');
    }
  };

  const downloadVoucher = async (comprobanteSelected, typeSelected) => {
    try {
      setLoadingComprobante(true);
      const responseDocument = await UserNetPrivada.responseDocumentComprobantePago({
        ...comprobanteSelected,
        isPDF: typeSelected === 'pdf',
      });

      if (responseDocument.status.id === 200) {
        const fixBase64 = responseDocument.data[0].file;
        const fileName = `Comprobante de Pago-${comprobanteSelected.serialNumber}-${comprobanteSelected.number}`;
        if (typeSelected === 'pdf' || typeSelected === 'xml') {
          const blob = base64toBlob(fixBase64, `application/${typeSelected}`);
          FileSaver.saveAs(blob, fileName);
        }
      } else {
        showErrorToast('Error al descargar Comprobante de pago');
      }
      setLoadingComprobante(false);
    } catch (error) {
      setLoadingComprobante(false);
      showErrorToast('Error al descargar Comprobante de pago');
    }
  };

  useEffect(() => {
    getListProductPolicies();
  }, []);

  useEffect(() => {
    if (productSelected) {
      getPoliciesByProduct();
    }
  }, [productSelected]);

  useEffect(() => {
    if (detailSelected) setLocationPolicy();
  }, [detailSelected]);

  useEffect(() => {
    if (dataPolicy) downloadPolicy(dataPolicy);
  }, [dataPolicy]);

  return !detailSelected ? (
    <ProductsByUser
      loading={loading}
      loadingDetails={loadingDetails}
      listProducts={productsByUser}
      selectUserProduct={selectUserProduct}
      productSelected={productSelected}
      selectDetail={selectDetail}
      listPoliceByProducts={listPoliceByProducts}
      beneficary={beneficary}
    />
  ) : (
    <DetailProductsByUser
      goBack={() => setDetailSelected(null)}
      user={user}
      getAnnuityInfo={getAnnuityInfo}
      detail={detailSelected}
      downloadTicket={downloadTicket}
      sendEmail={sendEmail}
      generateConstancy={generateConstancy}
      generatePolicy={generatePolicy}
      getComprobantePoliza={getComprobantePoliza}
      loadingConstancia={loadingConstancia}
      loadingPolicy={loadingPolicy}
      productSelected={productSelected}
      loadingTicket={loadingTicket}
      soatCertificadoB64={soatBase64}
      soatComprobanteBase64={soatComprobanteBase64}
      downloadVoucher={downloadVoucher}
      loadingDownload={loadingComprobante}
      validatePensioner={validatePensioner}
      isPensioner={isPensioner}
    />
  );
}
