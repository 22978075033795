import React, { useState, useEffect } from 'react';

import { OPTION_SEGMENT_BY_ID } from '../../../../scripts/Constants';

import Button, { ButtonColor } from '../../atoms/button/Button';
import { HeaderSection, ItemHistoryPromotions, PreviewImgPromotions } from '../../molecules';
import { ListMonthCardCalendar } from '../../organisms';
import ContainerCard from '../../molecules/containerCard/ContainerCard';
import CreateManagementPromotions from '../createManagementPromotions/CreateManagementPromotions';
import PSTabs from '../../PSTabs';
import PSModal from '../../PSModal';
import PSLoading from '../../PSLoading';
import PSIcon from '../../PSIcon';

import './index.scss';

const listMounts = [
  { src: 'jan.png', month: 'Enero' },
  { src: 'jan.png', month: 'Febrero' },
  { src: 'jan.png', month: 'Marzo' },
  { src: 'jan.png', month: 'Abril' },
  { src: 'jan.png', month: 'Mayo' },
  { src: 'jan.png', month: 'Junio' },
  { src: 'jan.png', month: 'Julio' },
  { src: 'jan.png', month: 'Agosto' },
  { src: 'jan.png', month: 'Setiembre' },
  { src: 'jan.png', month: 'Octubre' },
  { src: 'jan.png', month: 'Noviembre' },
  { src: 'jan.png', month: 'Diciembre' },
];

const currentDate = new Date();

export default function ManagementPromotions({
  tabsManagementPromotions,
  loading,
  dataHistory,
  onSelectTab,
  showModalManagementPromotions,
  openModal,
  onCloseModal,
  typeSelected,
  onSavePromotion,
  onChangePromotion,
  newPromotion,
  loadingSave,
  onUpdatePromotion,
  loadingUpdate,
  setLoading,
}) {
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(currentDate.getMonth());

  const goToPage = (url) => {
    if (!url.startsWith('netprivada/')) {
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    if (itemSelect) {
      setItemSelect(dataHistory[typeSelected].find((item) => item.sort === itemSelect.sort));
    }
  }, [dataHistory]);

  return (
    <div className="template-managementPromotions">
      <HeaderSection title="Promociones y Comunicados" />
      <div className="template-managementPromotions__body">
        <div className="container-principal">
          <PSTabs
            className="tabs-newsletter"
            options={tabsManagementPromotions}
            onSelecTab={(id) => {
              setLoading(true);
              setItemSelect(null);
              onSelectTab(id);
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }}
            hasFilters={false}
            hasButtonSubmit={false}
          />
          <div className="container-addPromotions">
            <Button type={ButtonColor.orange} icon="add" onClick={openModal} />
          </div>

          <ListMonthCardCalendar
            className="m-1"
            indexSelect={indexSelect}
            listMounts={listMounts}
            selectMonth={(index) => {
              setLoading(true);
              setItemSelect(null);
              setIndexSelect(index);
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }}
          />

          <>
            {itemSelect && (
              <div>
                <div className="m-1">
                  <span>Publicación Seleccionada</span>
                </div>
                <ContainerCard className="card-historyPromotions">
                  <table className="w-100">
                    <tbody>
                      <ItemHistoryPromotions data={itemSelect} />
                    </tbody>
                  </table>
                </ContainerCard>
              </div>
            )}

            <div>
              <div className="container-title-history">
                <span>Historial</span>
              </div>

              {loading ? (
                <div className="loading-container">
                  <PSLoading color="orange" />
                </div>
              ) : (
                <div className="container-detail-table">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th>Publicación</th>
                        {typeSelected === 3 && <th>Productos</th>}
                        <th>Estado</th>
                        <th>Creación</th>
                        <th>Vigencia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataHistory &&
                        dataHistory[typeSelected] &&
                        dataHistory[typeSelected]
                        .filter((item) => {
                            const isMonthMatch = item.month === listMounts[indexSelect].month;
                            const currentYear = currentDate.getFullYear();
                            const nextYear = currentYear + 1;
                            const isYearMatch = item.endDate.includes(currentYear) || item.endDate.includes(nextYear);
                            return isMonthMatch && isYearMatch;
                        })
                          .map((item, index) => {
                            return (
                              <ItemHistoryPromotions
                                index={indexSelect}
                                className={`m-1 ${
                                  item.status === 'Creado'
                                    ? 'create'
                                    : item.rangeVigency
                                    ? 'vigency'
                                    : 'expired'
                                } `}
                                data={item}
                                onselect={() => setItemSelect({ ...item })}
                              />
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </>
        </div>

        {itemSelect !== null && (
          <div className="container-preview">
            <PSIcon type="close" className="text-right" onClick={() => setItemSelect(null)} />
            <PreviewImgPromotions type="web" img={itemSelect.image.urlWeb} />
            <PreviewImgPromotions type="mobile" img={itemSelect.image.urlMobile} />
            {itemSelect.link && (
              <>
                <h6>Link de redireccionamiento:</h6>
                <div className="container-link">
                  <a className="hover" onClick={() => goToPage(itemSelect.link)}>
                    <span>{itemSelect.link}</span>
                  </a>
                </div>
              </>
            )}
            {typeSelected === 3 && (
              <ContainerCard className="container-segment">
                <label>Segmento</label>
                {itemSelect.products.map((item) => (
                  <span>{OPTION_SEGMENT_BY_ID[item]}</span>
                ))}
              </ContainerCard>
            )}

            {itemSelect.status.toLowerCase() !== 'publicado' && (
              <div className="d-flex-center mt-1">
                <Button
                  type={ButtonColor.blackPurple}
                  onClick={() => onUpdatePromotion(itemSelect.sort)}
                  loading={loadingUpdate}
                >
                  Publicar
                </Button>
                {/* <span className="update-img">Modificar</span> */}
              </div>
            )}
          </div>
        )}
      </div>
      {showModalManagementPromotions && (
        <PSModal
          open={showModalManagementPromotions}
          onClose={onCloseModal}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModalPreview',
          }}
        >
          <CreateManagementPromotions
            onSave={onSavePromotion}
            onChangePromotion={onChangePromotion}
            newPromotion={newPromotion}
            typeSelected={typeSelected}
            loadingSave={loadingSave}
            onClose={onCloseModal}
          />
        </PSModal>
      )}
    </div>
  );
}
