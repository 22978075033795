import React,{ useState }  from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import {Button, InputText} from "../../atoms";

const BoxInformation = ({ title, value }) => {
  return (
    <div className="box-information">
      <div className="container-body__display-value">{title}</div>
      <div className="container-body__value">{value}</div>
    </div>
  );
};

const BoxInput = ({ title, value, setValue }) => {
  return (
    <div className="box-information">
      <div className="container-body__display-value">{title}</div>
      <InputText
        className="container-body__value"
        onChange={setValue}
        value={value}
      />
    </div>
  );
}

const ContainerShare = React.forwardRef(({ user, className, send, ticketIds, closeModal }, ref) => {
  const [email, setEmail] = useState(user.mail);

  return (
    <div ref={ref} className={`container-new-category ${className}`}>
      <div className="container-title">
        <div className="container-title__title">Enviar las boletas</div>
        <b>correo electronico</b>
      </div>
      <div className="container-body">
        <BoxInformation title='Usuario' value={user.name}/>
        <BoxInput title='Correo' value={email} setValue={setEmail}/>

        {/*<div className="aditional-title">Correos adicionales</div>*/}
        {/*<BoxInput title='Correo' value={email} setValue={setEmail}/>*/}
      </div>
      <div className="container-button-modal">
        <Button onClick={() => send(ticketIds, email,closeModal)}>Enviar</Button>
      </div>
    </div>
    );
  }
);

ContainerShare.displayName = 'ContainerShare';

ContainerShare.defaultProps = {
  className: '',
  send: () => {},
};

ContainerShare.propTypes = {
  className: PropTypes.string,
  send: PropTypes.func,
};

export default ContainerShare;