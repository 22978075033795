import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import PSIcon from './PSIcon';
import { Button } from './atoms';
import PSTextInput from './PSTextInput';

const StyledPSTabs = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  color: #002655;
  ${(props) =>
    css`
      .tabs-options {
        display: flex;
        gap: 20px;
        .tab {
          opacity: 0.3;
          &:hover {
            opacity: 1;
            cursor: pointer;
          }
          .line-orange {
            margin-top: 5px;
            border: 1px solid #de6d19;
            display: block;
          }
        }
        .tab-selected {
          opacity: 1;
        }
      }
    `};
  .tabs-filter {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    justify-content: end;
    input {
      width: 170px;
      border: 1.5px solid #00265547;
      border-radius: 7px;
      height: 28px;
      background: url(/images/icons/ic_search.svg) #ffffff no-repeat calc(100% - 10px);
    }
    .buQcZt {
      margin: 0px;
      font-size: 14px;
    }
  }
`;

const PSTabs = React.forwardRef(
  (
    {
      className,
      options,
      loading,
      hasFilters,
      hasButtonSubmit,
      onSelecTab,
      onSubmit,
      onSearch,
      onClear,
      ...props
    },
    ref
  ) => {
    const [changeInput, setChangeInput] = useState('');
    const onChangeRUC = (e) => {
      const { value } = e.target;
      setChangeInput(value);
      onSearch(value);
    };

    useEffect(() => {
      setChangeInput('');
    }, [onClear]);

    return (
      <StyledPSTabs className={className} length={options.length} ref={ref} {...props}>
        <div className="tabs-options">
          {options.map((o) => (
            <div
              key={o.id}
              className={`tab ${o.selected ? 'tab-selected' : ''}`}
              onClick={() => onSelecTab(o.id)}
            >
              {o.name}
              {o.selected && <span className="line-orange" />}
            </div>
          ))}
        </div>
        {hasFilters && (
          <div className="tabs-filter">
            <PSTextInput placeholder="Buscar por RUC" value={changeInput} onChange={onChangeRUC} />
            <PSIcon type="calendar" />
          </div>
        )}
        {hasButtonSubmit && (
          <Button className="tabs-filter" onClick={onSubmit} loading={loading}>
            Publicar
          </Button>
        )}
      </StyledPSTabs>
    );
  }
);

PSTabs.displayName = 'PSTabs';

PSTabs.defaultProps = {
  children: null,
  options: [
    {
      id: 0,
      name: 'Solicitudes Nuevas',
      className: null,
      selected: true,
    },
  ],
  onSelecTab: () => {},
  onSubmit: () => {},
  hasFilters: true,
  hasButtonSubmit: false,
  className: '',
};

PSTabs.propTypes = {
  children: PropTypes.node,
  options: PropTypes.array,
  hasFilters: PropTypes.bool,
  onSelecTab: PropTypes.func,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  hasButtonSubmit: PropTypes.bool,
};

export default PSTabs;
